import axios from "axios";
import { apiUrl } from "../../config/config";
/* eslint-disable camelcase */

class deliveryService {
    
    /**
     *
     * Verificar area de delivery
     *
     * @param {Object} data
     * lat (latitude)
     * lng  (longitude)
     * rid (id restaurant)
     * @returns {object}
     * valid true/false
     * redirect url para redireccionar a doordash
     *
     */
    verifyDeliveryArea = (data) => {
        return new Promise((resolve, reject) => {
        axios
            .post(`${apiUrl}/verify-delivery-area`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };
}

const instance = new deliveryService();

export default instance;
