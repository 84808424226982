import axios from "axios";
import { apiUrl } from "../../config/config";
/* eslint-disable camelcase */

class templateService {

    /**
     * Consultar la data del template
     *
     * @param {Int} id de restaurante
     * @returns
     * data object
     */
    getTemplate = (id) => {
        return new Promise((resolve, reject) => {
        axios
            .get(`${apiUrl}/restaurant/${id}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

}

const instance = new templateService();

export default instance;
