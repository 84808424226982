import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from "react-google-places-autocomplete";
import { verifyDeliveryArea } from '../reducers/deliverySlice';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantId } from "../config/config";
import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en/en';
import fr from '../i18n/fr/fr';
import es from '../i18n/es/es';
i18n.addResourceBundle('en', 'header', en);
i18n.addResourceBundle('fr', 'header', fr);
i18n.addResourceBundle('es', 'header', es);

export const Header = (props) => {

	const { t } = useTranslation(['header']);

	const [value, setValue] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleCloseAlert()
		if (value) handleAddress(value);
	}, [value]);

	const [results, setResults] = useState(null);

	const handleAddress = (data) => {

		let results = null;

		//Obtener latitud y longitud por el place id 
		geocodeByPlaceId(data.value.place_id)
			.then((results) => getLatLng(results[0]))
			.then(({ lat, lng }) => {
				results = { lat, lng }
				setResults(results);
			});
	};

	//Hacer consulta al backend con lat y lng
	useEffect(() => {
		if (results) {
			handleVerifyDeliveryArea()
		}
	}, [results]);

	const dataDelivery = useSelector((state) => state.delivery.data);
	const isGetting = useSelector((state) => state.delivery.isGetting);

	const handleVerifyDeliveryArea = () => {

		let model = {
			lat: results.lat,
			lng: results.lng,
			rid: restaurantId,
		}

		dispatch(verifyDeliveryArea(model))
	}

	const [showAlert, setShowAlert] = useState(false);

	const handleCloseAlert = () =>{
		setShowAlert(false)
	}

	useEffect(()=>{
		if(dataDelivery){
			if(dataDelivery.valid){
				//redireccionar a doordash
				if(dataDelivery.redirect){
					window.location.href=dataDelivery.redirect;
				}
			}else{
				//mostrar alerta
				setShowAlert(true);
			}
		}
	},[dataDelivery]);

	const [url, setUrl] = useState(null);

	useEffect(()=>{
		if(props.data.url){
			setUrl(props.data.url);
		}
	},[props])

	return (
		<header id="header">
			<div className="intro" style={props.data.bgStyle}>
				<div className="overlay">
					<div className="container">
						<div className="row">
							<div className="col-md-8 col-md-offset-2 intro-text">
								<h1>
									{props.data.name ? props.data.name : ""}
									{/* <span> {t('ORDER')}</span> */}
								</h1>
								{/* <p>{props.data ? props.data.paragraph : 'Loading'}</p> */}
								{/* <a
								href='#features'
								className='btn btn-custom btn-lg page-scroll'
								>
								Learn More
								</a>{' '} */}
								{/* <input
									name="search"
									id="search"
									className="form-control"
									placeholder="Enter your address"
									required
								// onChange={handleChange}
								></input> */}
								<div id="container">
									{/* {showAlert && <>
										<div className="error-message">
											<p>We are sorry, we don’t offer delivery services in your current location.</p> 
											<i id="close" className="fa fa-times" onClick={handleCloseAlert}>
											</i>
										</div>
									</>}
									<GooglePlacesAutocomplete
										apiKey="AIzaSyBxBz8ZS0amAphgaVJmK67ccyIIm9kKUsk"
										apiOptions={{ language: 'fr', region: 'ca' }}
										onInputChange
										selectProps={{
											autoFocus: true,
											placeholder: "Number & street name",
											value,
											isClearable: true,
											onChange: setValue,
											onInputChange: handleCloseAlert,
											noOptionsMessage: () => ('Enter your address to verify that we deliver in your area'),
											isDisabled: isGetting,
											styles: {
												// input: (provided) => ({
												// 	...provided,
												// }),
												option: (provided) => ({
													...provided,
													textAlign: "left"
												}),
												// singleValue: (provided) => ({
												// 	...provided,
												// 	color: "#222222"
												// })
											}
										}}
									/> */}
									{url && <>
										<a className="btn btn-custom btn-lg" style={{ backgroundImage: `linear-gradient(to right, ${props.data.bgColor} 0%, ${props.data.bgColor} 100%)` }} href={url} target="_blank">{t('ORDER')}</a>
									</>}
								</div>
								{isGetting && <>
									<div class="fa-3x" style={{marginTop:20}}>
										<i class="fa fa-spinner fa-pulse" style={{color: '#e34254'}}></i>
									</div>
								</>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};