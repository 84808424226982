export const Footer = (props) => {
	return (
		<div>
			<div id='footer' style={{backgroundColor:props.data.bgColor}}>
				<div className='container text-center'>
					<p className='footer-copyright'>
						© {new Date().getFullYear()} Powered by
						<img className="logo-icon" src="img/atomic_kitchens.png" alt="logo"
							style={{ width: '13rem', marginLeft: '0.4rem', marginRight: '0.4rem' }}
						/>
					</p>
				</div>
			</div>
		</div>
	)
}
