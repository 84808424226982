import { createSlice } from "@reduxjs/toolkit";
import deliveryService from "../services/delivery/deliveryService";

/**
 *
 * Verificar area de delivery
 *
 * @param {Object} model
 * lat (latitude)
 * lng  (longitude)
 * id (id restaurant)
 * @returns {object}
 * valid true/false
 * redirect url para redireccionar a doordash
 *
 */
export const verifyDeliveryArea = (model) => async (dispatch) => {
  dispatch(getRequest());
    return deliveryService
        .verifyDeliveryArea(model)
        .then((data) => {
            return dispatch(getSuccess(data));
        })
        .catch((errors) => {
            if (errors.response && errors.response.status == 401) {
                return;
            }

            return dispatch(getError(errors));
        });
};

const initialState = {
    isGetting: false,
    data: null,
};

const deliverySlice = createSlice({
    name: "delivery",
    initialState,
    reducers: {
        getRequest: (state) => {
            state.isGetting = true;
        },
        getSuccess: (state, action) => {
            state.isGetting = false;
            state.data = action.payload;
            state.errors = [];
        },
        getError: (state, action) => {
            state.isGetting = false;
            state.errors = action.payload;
        },
    },
    extraReducers: {},
});

export const { getRequest, getSuccess, getError } = deliverySlice.actions;

export default deliverySlice.reducer;
