/**
 * Archivo de configuracion para obtener todas las 
 * variables de entorno y facilitar el uso
 * 
 * Crear los archivos siguientes con las variables de entorno
 * especificadas mas abajo
 * 
 * .env.development.local -> con yarn start se usa este .env
 * .env.production.local-> con yarn build se compila para producción
 */

//URL de backend
export const apiUrl = process.env.REACT_APP_API_URL;

//Url de imagenes
export const imgUrl = process.env.REACT_APP_IMG_URL;

//Url de imagenes
export const restaurantId = process.env.REACT_APP_REST_ID;