import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import en from '../i18n/en/en';
import fr from '../i18n/fr/fr';
import es from '../i18n/es/es';
i18n.addResourceBundle('en', 'header', en);
i18n.addResourceBundle('fr', 'header', fr);
i18n.addResourceBundle('es', 'header', es);

export const Navigation = (props) => {
	const { t } = useTranslation(['header']);

	//Cambio de lang
	function handleLanguageChange(lng) {
		i18n.changeLanguage(lng);
	}

	//Listado de lenguajes a colocar en el select
	const [langList, setLangList] = useState([]);

	const [pageTitle, setPageTitle] = useState({});

	useEffect(()=>{
		//Setear lenguajes
		setLangList(props.data.lang_list);

		//Setear titulos
		setPageTitle(props.data.page_title);
	},[props]);

	//
	useEffect(()=>{

		let page_title = pageTitle[i18n.language];

		if(page_title){
			document.title = page_title;
		}
	},[i18n.language]);

	return (
		<nav id='menu' className='navbar navbar-default navbar-fixed-top' style={{ backgroundColor: props.data.bgColor }}>
			<div className='container'>
				<div className='navbar-header'>
					{/* <button
						type='button'
						className='navbar-toggle collapsed'
						data-toggle='collapse'
						data-target='#bs-example-navbar-collapse-1'
					>
						{' '}
						<span className='sr-only'>Toggle navigation</span>{' '}
						<span className='icon-bar'></span>{' '}
						<span className='icon-bar'></span>{' '}
						<span className='icon-bar'></span>{' '}
					</button> */}
					{/* <a className='navbar-brand page-scroll' href='#page-top'></a> */}
					<a className='navbar-brand page-scroll'>
						{/* Restaurant Logo */}
						{props.data.imageLogo && <>
							<img src={props.data.imageLogo} style={{ width: '75px', top: 5, position: 'absolute' }}></img>
						</>}
					</a>{' '}
				</div>
				{/* <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
					<ul className='nav navbar-nav navbar-right'>
						<li className="dropdown-content-menu language-link list-inline-item dropdown">
							<a href="#" id="dropdownMenuLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="navbar-toggler text-uppercase">
								<i className="fa fa-globe" style={{marginRight:5}}></i>{'  '}{i18n.language}
							</a>
							<div aria-labelledby="dropdownMenuLanguage" className="dropdown-menu dropdown-menu-right">
								{langList.map((lng, index) => (
									<a key={index} className="dropdown-item" href="#" onClick={(ev) => {ev.preventDefault();handleLanguageChange(lng)}}>{t(lng.toUpperCase())}</a>
								))}
							</div>
						</li>
					</ul>
				</div> */}
				{/* <div
					className='collapse navbar-collapse'
					id='bs-example-navbar-collapse-1'
					>
					<ul className='nav navbar-nav navbar-right'>
						<li>
						<a href='#features' className='page-scroll'>
							Features
						</a>
						</li>
						<li>
						<a href='#about' className='page-scroll'>
							About
						</a>
						</li>
						<li>
						<a href='#services' className='page-scroll'>
							Services
						</a>
						</li>
						<li>
						<a href='#portfolio' className='page-scroll'>
							Gallery
						</a>
						</li>
						<li>
						<a href='#testimonials' className='page-scroll'>
							Testimonials
						</a>
						</li>
						<li>
						<a href='#team' className='page-scroll'>
							Team
						</a>
						</li>
						<li>
						<a href='#contact' className='page-scroll'>
							Contact
						</a>
						</li>
					</ul>
					</div> */}
			</div>
		</nav>
	)
}
