export const Colors = {
	tastyboom: "#ff5200",
	tastyboom_cl: "#e00918",
	black: "#000000",
	blue: "#36a0da",
	brown: "#AB8B64",
	brown_v2: "#824328",
	darkblue: "#146794",
	darkgreen: "#9DB667",
	darkgreen_v2: "#006738",
	green: "#8ab933",
	lightgrey: "#9E9E9E",
	orange: "#ed5434",
	pink: "#f52151",
	red: "#A94545",
	red_v2: "#e13737",
	white_orange: "#d1520b",
	white_red: "#e9031a",
	white_red_v2: "#e2031a",
	winesh: "#7B1010",
	yellow: "#db9b15",
	royal_blue: "#3514c0",
	lightpink: "#ef779b",
	extralightpink: "#febad6",
	extradarkblue: "#13006b",
};
