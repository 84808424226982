import { createSlice } from "@reduxjs/toolkit";
import templateService from "../services/template/templateService";

/**
 * Consultar la data del template
 *
 * @param {String} id de restaurante
 * @returns Object
 */
export const getTemplate = (id) => async (dispatch) => {
  dispatch(getRequest());
    return templateService
        .getTemplate(id)
        .then((data) => {
            return dispatch(getSuccess(data));
        })
        .catch((errors) => {
            if (errors.response && errors.response.status == 401) {
                return;
            }

            return dispatch(getError(errors));
        });
};

const initialState = {
    isGetting: false,
    data: null,
};

const templateSlice = createSlice({
    name: "template",
    initialState,
    reducers: {
        getRequest: (state) => {
            state.isGetting = true;
        },
        getSuccess: (state, action) => {
            state.isGetting = false;
            state.data = action.payload;
            state.errors = [];
        },
        getError: (state, action) => {
            state.isGetting = false;
            state.errors = action.payload;
        },
    },
    extraReducers: {},
});

export const { getRequest, getSuccess, getError } = templateSlice.actions;

export default templateSlice.reducer;
