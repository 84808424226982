import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {};

function getLang () {
	let lang = localStorage.getItem('lang');

	//console.log(process.env.REACT_APP_DEFAULT_LOCALE)
	
	if(lang === undefined || lang === null || !lang){
		return null;
	}
	if (lang && lang !== ''){
		try{
			return lang;
		}catch(err){
			return null;
		}
	}else{
		return null;
	}
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng:  'en',//getLang () || process.env.REACT_APP_DEFAULT_LOCALE,
		fallbackLng: 'en',
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
