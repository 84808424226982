import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
// import { Contact } from "./components/contact";
import { Loader } from "./components/loader";
import { Footer } from "./components/footer";
// import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { getTemplate } from './reducers/templateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantId } from "./config/config";
import { Colors } from './helpers';
import { imgUrl } from "./config/config";
import _ from 'lodash';
import i18n from './i18n/';
import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

function getFaviconEl() {
	return document.getElementById("favicon");
}

const App = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation()
	const [landingPageData, setLandingPageData] = useState(null);

	//Consultar data del template del restaurante
	useEffect(() => {
		dispatch(getTemplate(restaurantId));
		//setLandingPageData(JsonData);
	}, []);

	const [loadingTemplate, setLoadingTemplate] = useState(true);
	const dataTemplate = useSelector((state) => state.template.data);

	useEffect(() => {
		if(dataTemplate){
			
			let data = {}

			//Nombre de restaurante
			data.name = dataTemplate.name;

			//Obtener color de backgroud
			if(dataTemplate.template?.template_config?.template?.color){
				let colorName = dataTemplate.template.template_config.template.color;
				let color = Colors[colorName];
				data.bgColor = color ? color : ''
			}

			//Logo de header
			let imageLogo = dataTemplate.template.image_logo.nav;
			data.imageLogo = `${imgUrl}/logos/nav/${imageLogo}`;

			let style = {
				display: 'table',
				width: '100%',
				padding: 0,
				background: `url(../img/intro-bg.jpg) center center no-repeat`,
				backgroundColor: '#e5e5e5',
				WebkitBackgroundSize: 'cover',
				MozBackgroundSize: 'cover',
				backgroundSize: 'cover',
				OBackgroundSize: 'cover',
			}

			//Buscar imagen de background
			let template_config = dataTemplate.template.template_config;

			if(template_config){
				//buscar en el array welcome
				let result = _.find(template_config.menu, function(obj) {
					if (obj.page == 'welcome') {
						return true;
					}
				});
			
				if(result){
					if(result.header.image){
						let imageUrl = `${imgUrl}/headers/${result.header.image}`;
						style ={
							display: 'table',
							width: '100%',
							padding: 0,
							background: `url(${imageUrl}) center center no-repeat`,
							backgroundColor: '#e5e5e5',
							WebkitBackgroundSize: 'cover',
							MozBackgroundSize: 'cover',
							backgroundSize: 'cover',
							OBackgroundSize: 'cover',
						}
					}

					if(result.url){
						data.url = result.url;
					}
				}

				//Asignar favicon
				if(dataTemplate.template?.image_logo?.icon){
					const favicon = getFaviconEl();
    				favicon.href = `${imgUrl}/logos/icon/${dataTemplate.template.image_logo.icon}`;
				}

				//Lenguaje por defecto
				if (template_config.lang_default){
					i18n.changeLanguage(template_config.lang_default);

					//Titulo de la pagina
					if(result){
						if(result.page_title){
							let page_title = result.page_title[template_config.lang_default];

							data.page_title = result.page_title;
							if(page_title){
								document.title = page_title;
							}
						}
					}
				}

				//Listado de lenguajes
				if (template_config.lang_list && template_config.lang_list.length > 0){
					data.lang_list = template_config.lang_list;
				}
			}

			//Estilo de background e imagen
			data.bgStyle = style;

			setLandingPageData(data)
			//Mostrar la pagina al finalizar la consulta de 
			//la data del template
			setLoadingTemplate(false);
		}
	}, [dataTemplate]);
	return (
		<div>
			{loadingTemplate && <Loader />}
			{(!loadingTemplate && landingPageData) && <>
				<Navigation data={landingPageData}/>
				<Header data={landingPageData} />
				{/* <Features data={landingPageData.Features} /> */}
				{/* <About data={landingPageData.About} /> */}
				{/* <Services data={landingPageData.Services} /> */}
				{/* <Gallery data={landingPageData.Gallery}/> */}
				{/* <Testimonials data={landingPageData.Testimonials} /> */}
				{/* <Team data={landingPageData.Team} /> */}
				{/* <Contact data={landingPageData.Contact} /> */}
				<Footer data={landingPageData}/>
			</>}
		</div>
	);
};

export default App;
// eslint-disable-next-line
